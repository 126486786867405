<template>
  <Field
    :id="props.id"
    v-slot="{ handleChange, errors }"
    v-model="modelValue"
    :name="props.name"
    :rules="props.rules"
  >
    <UiTextarea
      :id="props.id"
      v-model="modelValue"
      :label="props.label"
      :placeholder="props.placeholder"
      :label-visible="props.labelVisible"
      :error="!!errors[0]"
      v-bind="$attrs"
      @change="handleChange"
    >
      <template
        v-for="(_, name) in $slots"
        #[name]="slotData"
      >
        <slot
          :name="name"
          v-bind="slotData"
        />
      </template>
    </UiTextarea>
    <slot
      v-if="props.showValidationMessageOutsideInput"
      name="error"
      :errors="errors"
    >
      <UiFieldMsg
        :errors="errors"
        :additional-msg="additionalMsg"
      />
    </slot>
  </Field>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { Field } from 'vee-validate'
import UiFieldMsg from '../UiFieldMsg/UiFieldMsg.vue'
import UiTextarea from '../UiTextarea/UiTextarea.vue'
import type { UiFormField } from '../UiForm.types'

const props = withDefaults(defineProps<UiFormField>(), {
  id: '',
  label: '',
  placeholder: '',
  name: '',
  disabled: false,
  rules: '',
  additionalMsg: '',
  showValidationMessageOutsideInput: true,
  labelVisible: false,
})

const $emit = defineEmits(['input'])
const modelValue = ref('')
watch(modelValue, (newQuestion) => {
  const value = newQuestion
  $emit('input', value)
})

watch(
  () => props.value,
  () => {
    modelValue.value = props.value
  },
  { immediate: true },
)
</script>
